import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/app.context";
import { ScheduleContext } from "../context/schedule.context";
import JobsHeader from "./jobs/JobsHeader";
import { ModelContext } from "../context/model.context";
import TableHeader from "./jobs/TableHeader";

export const ProjectListScreen = () => {
  const { setOpenJobsDetailModal } = useContext(AppContext);
  const { scheduleArray, projectListQuery, setDragObject, setDropObject } =
    useContext(ScheduleContext);
  const { filteredProjectsList } = useContext(ModelContext);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortedJobs, setSortedJobs] = useState(filteredProjectsList);
  const [currentJobs, setCurrentJobs] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [sortingState, setSortingState] = useState({
    field: "",
    direction: "", // asc || desc
  });

  // - - - - - - - - - -

  const onProjectDetailClick = (id) => {
    const project = scheduleArray.find((document) => document.id === id);
    setOpenJobsDetailModal({ show: true, jobsDetail: [project] });
    console.log(
      "ProjectListScreen onProjectDetailClick, id: ",
      id,
      ", project: ",
      project
    );
  };

  // - - - - - - - - - -

  const onUnscheduleClick = (id) => {
    if (window.confirm("Are you sure you want to Unschedule this job?")) {
      // User clicked OK
      // treating this action the same as if the user dragged to Unassigned column
      const unscheduledObject = { id: "Unassigned_RTS" };
      setDragObject(id);
      setDropObject(unscheduledObject);
      console.log("ProjectListScreen onUnscheduleClick id: ", id);
      //
    } else {
      // User clicked Cancel
    }
  };

  // - - - - - - - - - -

  const onSortClick = (field) => {
    if (sortingState?.field === field) {
      // const newDirection
      if (sortingState.direction === "asc") {
        setSortingState({ field, direction: "desc" });
      } else {
        setSortingState({});
      }
    } else {
      setSortingState({ field, direction: "asc" });
    }
  };

  // - - - - - - - - - -

  const onPageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setCurrPage(1);
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -
  //
  // sortingState, filteredProjectsList, projectListQuery change  -> setSortedJobs()
  // currPage, sortedJobs, pageSize changes                       -> setCurrentJobs()
  // currPage, filteredProjectsList, pageSize changes             -> setPagination()
  //

  // - - - - - - - - - -

  useEffect(() => {
    if (sortingState?.field && sortingState.direction) {
      console.log(filteredProjectsList, sortingState);
      const { field, direction } = sortingState;
      const sortedJobs = [...filteredProjectsList]
        .filter((project) => {
          // filter query by: invoiceNo, projectNo, firstName, lastName
          const queryLength = projectListQuery.length;
          const querySlice = projectListQuery
            .toLowerCase()
            .slice(0, queryLength);
          const invoiceNoSlice = project.invoiceNo
            ?.toString()
            .slice(0, queryLength);
          const projectNoSlice = project.lowes_projectNo?.slice(0, queryLength);
          const phoneNoSlice = project.customer_cellPhone?.slice(
            0,
            queryLength
          );
          const projectFirstnameSlice = project.customer_firstName
            ?.toLowerCase()
            .slice(0, queryLength);
          const projectLastnameSlice = project.customer_lastName
            ?.toLowerCase()
            .slice(0, queryLength);
          return (
            querySlice === projectLastnameSlice ||
            querySlice === projectFirstnameSlice ||
            querySlice === invoiceNoSlice ||
            querySlice === projectNoSlice ||
            querySlice === phoneNoSlice
          );
        })
        .sort((a, b) => {
          if (!a[field]) {
            return direction === "asc" ? -1 : 1;
          }
          if (!b[field]) {
            return direction === "asc" ? 1 : -1;
          }

          if (direction === "asc") {
            if (typeof a[field] === "string" && typeof b[field] === "string") {
              // sorting string value
              return a[field]?.toLowerCase() < b[field]?.toLowerCase() ? -1 : 1;
            } else {
              // sorting string number
              return a[field] - b[field];
            }
          } else {
            if (typeof a[field] === "string" && typeof b[field] === "string") {
              // sorting string value
              return a[field]?.toLowerCase() < b[field]?.toLowerCase() ? 1 : -1;
            } else {
              // sorting string number
              return b[field] - a[field];
            }
          }
        });

      setSortedJobs(sortedJobs);
    } else {
      const unsortedJobs = [...filteredProjectsList].filter((project) => {
        // filter query by: invoiceNo, projectNo, firstName, lastName
        const queryLength = projectListQuery.length;
        const querySlice = projectListQuery.toLowerCase().slice(0, queryLength);
        const invoiceNoSlice = project.invoiceNo
          ?.toString()
          .slice(0, queryLength);
        const projectNoSlice = project.lowes_projectNo?.slice(0, queryLength);
        const phoneNoSlice = project.customer_cellPhone?.slice(0, queryLength);
        const projectFirstnameSlice = project.customer_firstName
          ?.toLowerCase()
          .slice(0, queryLength);
        const projectLastnameSlice = project.customer_lastName
          ?.toLowerCase()
          .slice(0, queryLength);
        return (
          querySlice === projectLastnameSlice ||
          querySlice === projectFirstnameSlice ||
          querySlice === invoiceNoSlice ||
          querySlice === projectNoSlice ||
          querySlice === phoneNoSlice
        );
      });
      setSortedJobs([...unsortedJobs]);
    }
  }, [sortingState, filteredProjectsList, projectListQuery]);

  // - - - - - - - - - -

  useEffect(() => {
    const startIndex = (currPage - 1) * pageSize;
    const endIndex = currPage * pageSize;
    setCurrentJobs(sortedJobs.slice(startIndex, endIndex));
  }, [currPage, sortedJobs, pageSize]);

  // - - - - - - - - - -

  useEffect(() => {
    console.log(
      "ProjectListScreen useEffect[currPage, pageSize, sortedJobs] filteredProjectsCount: ",
      sortedJobs.length
    );
    let newPagination = [];
    const totalCount = Math.ceil(sortedJobs.length / pageSize);
    if (totalCount < 11) {
      newPagination = Array(totalCount)
        .fill(null)
        .map((_, index) => {
          return index + 1;
        });
    } else {
      // Always print first page button
      newPagination.push(1);

      // Print "..." only if currentPage is > 6
      if (currPage > 6) {
        newPagination.push("...");
      }

      // Print previous number button
      if (currPage > 6 && currPage < totalCount - 4) {
        Array(3)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage - 3 + index);
          });
      }

      if (currPage > 2 && currPage < 7) {
        Array(currPage - 2)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(2 + index);
          });
      }

      if (currPage > 6 && currPage > totalCount - 5) {
        Array(currPage - (totalCount - 7))
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(totalCount - 7 + index);
          });
      }

      //Print current page number button as long as it not the first or last page
      if (currPage !== 1 && currPage !== totalCount) {
        newPagination.push(currPage);
      }

      //print next number button
      if (currPage < totalCount - 4 && currPage > 5) {
        Array(2)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage + 1 + index);
          });
      }
      if (currPage < totalCount - 1 && currPage < 6 && currPage !== 1) {
        Array(8 - currPage)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage + 1 + index);
          });
      }

      if (currPage < totalCount - 1 && currPage > totalCount - 5) {
        Array(totalCount - currPage - 1)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage + 1 + index);
          });
      }

      // special case where first page is selected...
      if (currPage === 1) {
        Array(7)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage + 1 + index);
          });
      }

      // print "..." if currentPage is < lastPage - 4
      if (currPage < totalCount - 4) {
        newPagination.push("...");
      }

      //Always print last page button if there is more than 1 page
      newPagination.push(totalCount);
    }
    setPagination(newPagination);
  }, [currPage, pageSize, sortedJobs.length]);

  // - - - - - - - - - -

  return (
    <div className="flex flex-col px-4 pt-8 gap-4 h-full">
      <div>
        <JobsHeader></JobsHeader>
      </div>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="table-container">
          <table className="sch-grid">
            <thead>
              <tr>
                <th className="w-40">
                  <TableHeader
                    sortingState={sortingState}
                    field="lowes_projectNo"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Job#
                  </TableHeader>
                </th>
                <th className="w-31">
                  <TableHeader
                    sortingState={sortingState}
                    field="surface"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Surface
                  </TableHeader>
                </th>
                <th className="w-56">
                  <TableHeader
                    sortingState={sortingState}
                    field="customer_firstName"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Customer
                  </TableHeader>
                </th>
                <th>
                  <TableHeader
                    sortingState={sortingState}
                    field="customer_address"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Address
                  </TableHeader>
                </th>
                <th className="w-31">
                  <TableHeader
                    sortingState={sortingState}
                    field="customer_cellPhone"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Phone
                  </TableHeader>
                </th>
                <th className="w-[150px]">
                  <TableHeader
                    sortingState={sortingState}
                    field="classification"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Type
                  </TableHeader>
                </th>
                <th className="w-24">
                  <TableHeader
                    sortingState={sortingState}
                    field="lowes_storeNo"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Store#
                  </TableHeader>
                </th>
                <th className="w-31">
                  <TableHeader
                    sortingState={sortingState}
                    field="date_scheduled"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Scheduled
                  </TableHeader>
                </th>
                <th className="w-31">
                  <TableHeader
                    sortingState={sortingState}
                    field="date_estimatedCompletion"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Completion
                  </TableHeader>
                </th>
                <th className="w-24">
                  <TableHeader
                    sortingState={sortingState}
                    field="installer"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Installer
                  </TableHeader>
                </th>
                <th className="w-20">
                  <TableHeader
                    sortingState={sortingState}
                    field="statusCode"
                    sortChange={(event) => onSortClick(event)}
                  >
                    Status
                  </TableHeader>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentJobs.length ? (
                currentJobs.map((document) => {
                  return (
                    <tr key={document.id}>
                      <td>
                        <button
                          className="text-skin-link hover:opacity-75"
                          onClick={() => onProjectDetailClick(document.id)}
                        >
                          {document.lowes_projectNo
                            ? document.lowes_projectNo
                            : ""}
                        </button>
                      </td>
                      <td>{document.surface ? document.surface : ""}</td>
                      <td>
                        {document.customer_firstName &&
                        document.customer_lastName
                          ? document.customer_firstName +
                            " " +
                            document.customer_lastName
                          : ""}
                      </td>
                      <td>
                        {document.customer_address
                          ? document.customer_address
                          : ""}
                      </td>
                      <td>
                        {document.customer_cellPhone
                          ? document.customer_cellPhone
                          : ""}
                      </td>
                      <td>
                        {document.classification ? document.classification : ""}
                      </td>
                      <td>
                        {document.lowes_storeNo ? document.lowes_storeNo : ""}
                      </td>

                      <td>
                        <button
                          className="text-skin-link hover:opacity-75"
                          onClick={() => onUnscheduleClick(document.id)}
                        >
                          {document.date_scheduled
                            ? document.date_scheduled + " 🚫"
                            : ""}
                        </button>
                      </td>
                      <td>
                        {document.date_estimatedCompletion
                          ? document.date_estimatedCompletion
                          : ""}
                      </td>
                      <td>{document.installer ? document.installer : ""}</td>
                      <td>
                        {document["statusCode"] ? document["statusCode"] : ""}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex w-full px-4 py-2 text-sm font-normal bg-skin-primary fill-skin-base items-center">
          <div className="flex-1">
            {" "}
            {(currPage - 1) * pageSize + 1}-
            {currPage * pageSize < sortedJobs.length
              ? currPage * pageSize
              : sortedJobs.length}{" "}
            of {sortedJobs.length}
          </div>
          <div className="flex gap-2 font-semibold">
            <button
              onClick={() => setCurrPage(currPage - 1)}
              disabled={currPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
              >
                <path d="M15.9547 5.46394L9.41396 12.0047L15.9547 18.5454C16.3452 18.9359 16.3452 19.5691 15.9547 19.9596C15.5641 20.3501 14.931 20.3501 14.5405 19.9596L7.29266 12.7118C7.10506 12.5242 6.99976 12.2699 6.99976 12.0047C6.99976 11.7395 7.10506 11.4851 7.29266 11.2976L14.5405 4.04973C14.5893 4.00091 14.6419 3.9582 14.6973 3.92159C15.0855 3.66531 15.613 3.70802 15.9547 4.04973C16.3452 4.44025 16.3452 5.07342 15.9547 5.46394Z" />
              </svg>
            </button>
            {pagination.map((page, index) => (
              <button
                onClick={() => setCurrPage(page)}
                className={`${
                  page === currPage
                    ? "text-yellow border-b border-b-yellow-30"
                    : ""
                } min-w-4`}
                key={index}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => setCurrPage(currPage + 1)}
              disabled={currPage * pageSize >= sortedJobs.length}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
              >
                <path d="M9.04533 18.5361L15.586 11.9953L9.04533 5.4546C8.65481 5.0641 8.65481 4.4309 9.04533 4.0404C9.43586 3.6499 10.069 3.6499 10.4595 4.0404L17.7073 11.2882C17.8949 11.4758 18.0002 11.7301 18.0002 11.9953C18.0002 12.2605 17.8949 12.5149 17.7073 12.7024L10.4595 19.9503C10.4107 19.9991 10.3581 20.0418 10.3027 20.0784C9.91452 20.3347 9.38704 20.292 9.04533 19.9503C8.65481 19.5598 8.65481 18.9266 9.04533 18.5361Z" />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex justify-end items-center gap-2">
            Rows per Page:{" "}
            <select
              id="page-size"
              className="bg-skin-primary text-skin-primary text-sm font-medium hover:opacity-75 cursor-pointer arrow-link"
              onChange={(event) => onPageSizeChange(event.target.value)}
              value={pageSize}
            >
              {[10, 20, 50, 100].map((count, index) => (
                <option value={count} key={index}>
                  {count}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
