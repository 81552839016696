import { useContext, useState, useEffect } from "react";
import { AppContext } from "../context/app.context";
import { ModelContext } from "../context/model.context";
import { ScheduleContext } from "../context/schedule.context";
import { COLUMN_DAY_ARRAY } from "../utility/keys";
import UnassignedProjects from "../components/UnassignedProjects";
import JobTile from "./schedule/JobTile";
import ScheduleCell from "./schedule/ScheduleCell";
import ScheduleHeader from "./schedule/ScheduleHeader";

// NOTE: got help with filtering dates, using start & end values, for overlapping calculations from webpage:
//        https://stackoverflow.com/questions/17304426/javascript-date-range-between-date-range
//              An overlap test is slightly more complex. The following will return true if the two date ranges overlap, regardless of order.

export const ScheduleScreen = ({ children }) => {
  const {
    weekDatesArray,
    setOpenJobsDetailModal,
    dataFormatting_payrollFields,
  } = useContext(AppContext);
  const { filteredCandidatesList } = useContext(ModelContext);
  const {
    scheduleArray,
    dragObject,
    setDragObject,
    setDropObject,
    furnitureArray,
    floatingArray,
    glueDownArray,
    nailDownArray,
    vinylArray,
    tileArray,
    backsplashArray,
  } = useContext(ScheduleContext);
  const [softExtraCellArray, setSoftExtraCellArray] = useState([]);
  const [hardExtraCellArray, setHardExtraCellArray] = useState([]);
  const [softExtraCellRowsArray, setSoftExtraCellRowsArray] = useState([]);
  const [hardExtraCellRowsArray, setHardExtraCellRowsArray] = useState([]);

  // - - - - - - - - - -

  const onProjectDetailClick = (project, event) => {
    event?.stopPropagation();
    console.log("ScheduleScreen onProjectDetailClick, project: ", project);
    // document.getElementById(SCREENS_PROJECTDETAIL_ID).style.display = "block";

    if (project.extraCell) {
      // extra cell
      // setOpenExtraCellDetailModal({
      //   show: true,
      //   jobsDetail: [project],
      // });
    } else {
      // job
      setOpenJobsDetailModal({ show: true, jobsDetail: [project] });
    }
  };

  // - - - - - - - - - -

  const allowDropHere = (event) => {
    event.preventDefault();
  };

  // - - - - - - - - - -

  const onDragStarted = (id) => {
    // event.dataTransfer.setData("div", event.target.id);
    setDragObject(id);
    console.log("ScheduleScreen dragStarted id: ", id);
  };

  // - - - - - - - - - -

  const hasBeenDropped = (event, id, content) => {
    event.preventDefault();
    if (content.props?.project?.extraCell) {
      // only allow job dropped on extraCell
      var droppedJob = {};
      droppedJob = scheduleArray.find((document) => {
        return document.id === dragObject;
      });

      const droppedOntoSurface = content.props?.project?.surface;
      const droppedJobSurface = droppedJob.surface;
      if (
        (droppedJobSurface === "Carpet" && droppedOntoSurface === "Carpet") ||
        (droppedJobSurface !== "Carpet" && droppedOntoSurface !== "Carpet")
      ) {
        // only allow same surface drops
        const droppedObject = { id: id, content: content };
        setDropObject(droppedObject);
        console.log(
          "ScheduleScreen hasBeenDropped onto extraCell id: ",
          id,
          ", content: ",
          content,
          ", droppedJob: ",
          droppedJob
        );
      } else {
        // non-matching surface types
        console.log(
          "ScheduleScreen hasBeenDropped onto extraCell BUT IGNORED due to surface mis-match, content: ",
          content,
          ", droppedJob: ",
          droppedJob
        );
      }
      //
    } else if (!content.props) {
      // don't allow job to be dropped onto job
      console.log(
        "ScheduleScreen hasBeenDropped onto another non-job cell, id: ",
        id,
        ", content: ",
        content
      );
      const droppedObject = { id: id, content: content };
      setDropObject(droppedObject);
      //
    } else {
      // don't allow job to be dropped onto job
      console.log(
        "ScheduleScreen hasBeenDropped onto another job SO IGNORING id: ",
        id,
        ", content: ",
        content
      );
    }
  };

  // - - - - - - - - - -

  const renderDateHeaderRow = () => {
    return COLUMN_DAY_ARRAY.map((value, index) => {
      return (
        <ScheduleCell
          key={index}
          className="bg-header [&:nth-child(n+7)]:bg-header-lighter"
        >
          <div
            className="h-full border-b-2
            border-yellow text-sm flex flex-col justify-center items-center px-2"
          >
            <div className="flex justify-center w-full">
              <span className="flex-auto max-w-4.5"></span>
              <span className="font-medium text-nowrap">
                {value + " " + weekDatesArray[index]}
              </span>
            </div>
          </div>
        </ScheduleCell>
      );
    });
  };

  // - - - - - - - - - -

  const renderSoftCrewCell = (document, candidateIndex) => {
    const softSurface_furnitureAvailable =
      furnitureArray[0]?.softSurface.available;
    const softSurface_floatingAvailable =
      floatingArray[0]?.softSurface.available;
    const softSurface_glueDownAvailable =
      glueDownArray[0]?.softSurface.available;
    const softSurface_nailDownAvailable =
      nailDownArray[0]?.softSurface.available;
    const softSurface_vinylAvailable = vinylArray[0]?.softSurface.available;
    const softSurface_tileAvailable = tileArray[0]?.softSurface.available;
    const softSurface_backsplashAvailable =
      backsplashArray[0]?.softSurface.available;

    // add tooltips to emoji icons
    var tooltipText = "";
    tooltipText = `🛟f 🖌️g 🔨n\n` + `📄v 🪨t 🌊b\n`;

    return (
      <span className="mx-2 self-center font-light">
        <span className="font-bold tooltip">
          <button
            key={document.__ID_Candidate}
            className="w-full text-black text-sm font-semibold flex-1 basis-32 "
          >
            {document.Scheduling_Surface}
            <br></br>
            {candidateIndex
              .toString()
              .concat(
                " ",
                candidateIndex + 1 <= softSurface_furnitureAvailable ? "🪑" : ""
              )
              .concat(
                candidateIndex + 1 <= softSurface_floatingAvailable ? "🛟" : ""
              )
              .concat(
                candidateIndex + 1 <= softSurface_glueDownAvailable ? "🖌️" : ""
              )
              .concat(
                candidateIndex + 1 <= softSurface_nailDownAvailable ? "🔨" : ""
              )
              .concat(
                candidateIndex + 1 <= softSurface_vinylAvailable ? "📄" : ""
              )
              .concat(
                candidateIndex + 1 <= softSurface_tileAvailable ? "🪨" : ""
              )
              .concat(
                candidateIndex + 1 <= softSurface_backsplashAvailable
                  ? "🌊"
                  : ""
              )}
          </button>
          <span className="tooltiptext">{tooltipText}</span>
        </span>
      </span>
    );
  };

  // - - - - - - - - - -

  const renderHardCrewCell = (document, candidateIndex) => {
    const hardSurface_furnitureAvailable =
      furnitureArray[0]?.hardSurface.available;
    const hardSurface_floatingAvailable =
      floatingArray[0]?.hardSurface.available;
    const hardSurface_glueDownAvailable =
      glueDownArray[0]?.hardSurface.available;
    const hardSurface_nailDownAvailable =
      nailDownArray[0]?.hardSurface.available;
    const hardSurface_vinylAvailable = vinylArray[0]?.hardSurface.available;
    const hardSurface_tileAvailable = tileArray[0]?.hardSurface.available;
    const hardSurface_backsplashAvailable =
      backsplashArray[0]?.hardSurface.available;

    const softCrewFilteredCount = filteredCandidatesList.filter(
      (crewDocument) => crewDocument?.Scheduling_Surface === "Soft Surface"
    ).length;

    // add tooltips to emoji icons
    var tooltipText = "";
    tooltipText = `🛟f 🖌️g 🔨n\n` + `📄v 🪨t 🌊b\n`;

    return (
      <span className="mx-2 self-center font-light">
        <span className="font-bold tooltip">
          <button
            key={document.__ID_Candidate}
            className="w-full text-black text-sm font-semibold flex-1 basis-32 "
          >
            {document.Scheduling_Surface}
            <br></br>
            {candidateIndex
              .toString()
              .concat(
                " ",
                candidateIndex + 1 - softCrewFilteredCount <=
                  hardSurface_furnitureAvailable
                  ? "🪑"
                  : ""
              )
              .concat(
                candidateIndex + 1 - softCrewFilteredCount <=
                  hardSurface_floatingAvailable
                  ? "🛟"
                  : ""
              )
              .concat(
                candidateIndex + 1 - softCrewFilteredCount <=
                  hardSurface_glueDownAvailable
                  ? "🖌️"
                  : ""
              )
              .concat(
                candidateIndex + 1 - softCrewFilteredCount <=
                  hardSurface_nailDownAvailable
                  ? "🔨"
                  : ""
              )
              .concat(
                candidateIndex + 1 - softCrewFilteredCount <=
                  hardSurface_vinylAvailable
                  ? "📄"
                  : ""
              )
              .concat(
                candidateIndex + 1 - softCrewFilteredCount <=
                  hardSurface_tileAvailable
                  ? "🪨"
                  : ""
              )
              .concat(
                candidateIndex + 1 - softCrewFilteredCount <=
                  hardSurface_backsplashAvailable
                  ? "🌊"
                  : ""
              )}
          </button>
          <span className="tooltiptext">{tooltipText}</span>
        </span>
      </span>
    );
  };

  // - - - - - - - - - -

  const renderSoftExtraCellContent = (columnIndex, candidateIndex, value) => {
    const content = renderSoftSurfaceExtraCells(columnIndex, candidateIndex);
    return (
      <div
        className={`${
          content === "UNAVAILABLE" ? "" : "bg-skin-surface-soft"
        } h-full flex flex-col`}
        onDrop={(e) => hasBeenDropped(e, "Unassigned_" + value, content)}
        onDragOver={(e) => content !== "UNAVAILABLE" && allowDropHere(e)}
      >
        {
          // TEMP - change 2nd content to "" to fix
          content !== "UNAVAILABLE" ? content : ""
        }
      </div>
    );
  };

  // - - - - - - - - - -

  const renderHardExtraCellContent = (columnIndex, candidateIndex, value) => {
    const content = renderHardSurfaceExtraCells(columnIndex, candidateIndex);
    return (
      <div
        className={`${
          content === "UNAVAILABLE" ? "" : "bg-skin-surface-hard"
        } h-full flex flex-col`}
        onDrop={(e) => hasBeenDropped(e, "Unassigned_" + value, content)}
        onDragOver={(e) => content !== "UNAVAILABLE" && allowDropHere(e)}
      >
        {
          // TEMP - change 2nd content to "" to fix
          content !== "UNAVAILABLE" ? content : ""
        }
      </div>
    );
  };

  // - - - - - - - - - -

  const renderSoftCellContent = (columnIndex, candidateIndex, crew, value) => {
    const content = renderSoftSurfaceCells(columnIndex, candidateIndex, crew);
    return crew.Scheduling_Surface === "Soft Surface" ? (
      <div
        className={`${
          content === "UNAVAILABLE"
            ? ""
            : crew.Scheduling_Surface === "Soft Surface"
            ? "bg-skin-surface-soft"
            : "bg-skin-surface-hard"
        } h-full flex flex-col`}
        onDrop={(e) => hasBeenDropped(e, "Unassigned_" + value, content)}
        onDragOver={(e) => content !== "UNAVAILABLE" && allowDropHere(e)}
      >
        {content !== "UNAVAILABLE" ? content : ""}
      </div>
    ) : (
      ""
    );
  };

  // - - - - - - - - - -

  const renderHardCellContent = (columnIndex, candidateIndex, crew, value) => {
    const content = renderHardSurfaceCells(columnIndex, candidateIndex, crew);
    return crew.Scheduling_Surface === "Hard Surface" ? (
      <div
        className={`${
          content === "UNAVAILABLE"
            ? ""
            : crew.Scheduling_Surface === "Soft Surface"
            ? "bg-skin-surface-soft"
            : "bg-skin-surface-hard"
        } h-full flex flex-col`}
        onDrop={(e) => hasBeenDropped(e, "Unassigned_" + value, content)}
        onDragOver={(e) => content !== "UNAVAILABLE" && allowDropHere(e)}
      >
        {content !== "UNAVAILABLE" ? content : ""}
      </div>
    ) : (
      ""
    );
  };

  // - - - - - - - - - -

  const renderSoftSurfaceCells = (
    columnIndex,
    candidateIndex,
    crewDocument
  ) => {
    const softSurface_furnitureAvailable =
      furnitureArray[columnIndex]?.softSurface.available;
    const softSurface_furnitureFilled =
      furnitureArray[columnIndex]?.softSurface.filled;
    const softSurface_floatingAvailable =
      floatingArray[columnIndex]?.softSurface.available;
    const softSurface_floatingFilled =
      floatingArray[columnIndex]?.softSurface.filled;
    const softSurface_glueDownAvailable =
      glueDownArray[columnIndex]?.softSurface.available;
    const softSurface_glueDownFilled =
      glueDownArray[columnIndex]?.softSurface.filled;
    const softSurface_nailDownAvailable =
      nailDownArray[columnIndex]?.softSurface.available;
    const softSurface_nailDownFilled =
      nailDownArray[columnIndex]?.softSurface.filled;
    const softSurface_vinylAvailable =
      vinylArray[columnIndex]?.softSurface.available;
    const softSurface_vinylFilled = vinylArray[columnIndex]?.softSurface.filled;
    const softSurface_tileAvailable =
      tileArray[columnIndex]?.softSurface.available;
    const softSurface_tileFilled = tileArray[columnIndex]?.softSurface.filled;
    const softSurface_backsplashAvailable =
      backsplashArray[columnIndex]?.softSurface.available;
    const softSurface_backsplashFilled =
      backsplashArray[columnIndex]?.softSurface.filled;

    const softCrewFilteredCount = filteredCandidatesList.filter(
      (crewDocument) => crewDocument?.Scheduling_Surface === "Soft Surface"
    ).length;
    // array(objects) of unassigned but scheduled soft surface jobs for this date
    // 2024-12-18 soft surface now includes Carpet & sheetVinyl (determined by JOB_SHEETVINYL_SKULIST)
    const cellScheduleList = scheduleArray
      .filter(
        (project) =>
          project?.surface === "Carpet" &&
          crewDocument?.Scheduling_Surface === "Soft Surface"
      )
      .filter(
        (project) =>
          ((new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(project.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(project.end).getTime())) &&
          project?.installer === ""
      )
      .filter(
        (project) =>
          project?.encodedSmallTitle !== "" && project?.encodedLargeTitle !== ""
      )
      .filter((project) => !project?.extraCell) // filter out extraCells;
      .filter((project) => !project?.extraCellId); // filter out jobs married to extraCells;
    // array(objects) of assigned and scheduled soft & hard surface jobs for this date
    const assignedList = scheduleArray
      .filter(
        (project) =>
          ((new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(project.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(project.end).getTime())) &&
          project?.installer !== ""
      )
      .filter(
        (project) =>
          project?.encodedSmallTitle !== "" && project?.encodedLargeTitle !== ""
      );
    // array(strings) of payrollNos for assigned and scheduled soft & hard surface jobs for this date
    const assignedPayrollList = assignedList.map((job) => {
      // DEBUG: console the list with payrollNo & invoiceNo
      // return { payrollNo: job.installer, invoiceNo: job.invoiceNo };
      return job.installer;
    });
    // array(strings) of payrollNos for assigned and scheduled soft surface crews for this date
    const softAssignedPayrollList = assignedPayrollList.filter(
      (assignedPayrollNo) =>
        filteredCandidatesList.filter(
          (crew) =>
            dataFormatting_payrollFields(crew.payrollNo) ===
              assignedPayrollNo && crew.Scheduling_Surface === "Soft Surface"
        ).length > 0
    );
    // array(strings) of unique payrollNo's
    const softAssignedUniquePayrollList = [...new Set(softAssignedPayrollList)];
    //
    //
    // console.log(
    //   "renderSoftSurfaceCells assignedPayrollList: ",
    //   assignedPayrollList
    // );
    // console.log(
    //   "renderSoftSurfaceCells softAssignedPayrollList: ",
    //   softAssignedPayrollList
    // );
    // console.log(
    //   "renderSoftSurfaceCells softAssignedUniquePayrollList: ",
    //   softAssignedUniquePayrollList
    // );
    //

    if (
      candidateIndex - softCrewFilteredCount - 1 &&
      cellScheduleList[candidateIndex]?.id
    ) {
      return (
        // show job by cellIndex
        <JobTile
          key={cellScheduleList[candidateIndex]?.id}
          project={cellScheduleList[candidateIndex]}
          projectDetailClick={onProjectDetailClick}
          dragStarted={onDragStarted}
        ></JobTile>
      );
    } else if (
      -1 * (candidateIndex - softCrewFilteredCount) <=
      softAssignedUniquePayrollList.length
    ) {
      return "UNAVAILABLE";
    } else if (
      candidateIndex + 1 <=
        softSurface_furnitureAvailable - softSurface_furnitureFilled ||
      candidateIndex + 1 <=
        softSurface_floatingAvailable - softSurface_floatingFilled ||
      candidateIndex + 1 <=
        softSurface_glueDownAvailable - softSurface_glueDownFilled ||
      candidateIndex + 1 <=
        softSurface_nailDownAvailable - softSurface_nailDownFilled ||
      candidateIndex + 1 <=
        softSurface_vinylAvailable - softSurface_vinylFilled ||
      candidateIndex + 1 <=
        softSurface_tileAvailable - softSurface_tileFilled ||
      candidateIndex + 1 <=
        softSurface_backsplashAvailable - softSurface_backsplashFilled
    ) {
      var resultString = "";
      resultString =
        candidateIndex + 1 <=
        softSurface_furnitureAvailable - softSurface_furnitureFilled
          ? resultString.concat("🪑")
          : resultString;
      resultString =
        candidateIndex + 1 <=
        softSurface_floatingAvailable - softSurface_floatingFilled
          ? resultString.concat("🛟")
          : resultString;
      resultString =
        candidateIndex + 1 <=
        softSurface_glueDownAvailable - softSurface_glueDownFilled
          ? resultString.concat("🖌️")
          : resultString;
      resultString =
        candidateIndex + 1 <=
        softSurface_nailDownAvailable - softSurface_nailDownFilled
          ? resultString.concat("🔨")
          : resultString;
      resultString =
        candidateIndex + 1 <=
        softSurface_vinylAvailable - softSurface_vinylFilled
          ? resultString.concat("📄")
          : resultString;
      resultString =
        candidateIndex + 1 <= softSurface_tileAvailable - softSurface_tileFilled
          ? resultString.concat("🪨")
          : resultString;
      resultString =
        candidateIndex + 1 <=
        softSurface_backsplashAvailable - softSurface_backsplashFilled
          ? resultString.concat("🌊")
          : resultString;
      return resultString;
    } else {
      return "";
    }

    // return candidateIndex < softCrewFilteredCount - 1 &&
    //   cellScheduleList[candidateIndex]?.id ? ( // show job by cellIndex
    //   <JobTile
    //     key={cellScheduleList[candidateIndex]?.id}
    //     project={cellScheduleList[candidateIndex]}
    //     projectDetailClick={onProjectDetailClick}
    //     dragStarted={onDragStarted}
    //   ></JobTile>
    // ) : // this formula reverses/pushes UNAVAILABLE cells to bottom, working up
    // -1 * (candidateIndex - softCrewFilteredCount) <=
    //   softAssignedUniquePayrollList.length ? (
    //   "UNAVAILABLE"
    // ) : candidateIndex + 1 <=
    //   softSurface_furnitureAvailable - softSurface_furnitureFilled ? (
    //   "🪑"
    // ) : (
    //   ""
    // );
  };

  // - - - - - - - - - -

  const renderHardSurfaceCells = (
    columnIndex,
    candidateIndex,
    crewDocument
  ) => {
    const hardSurface_furnitureAvailable =
      furnitureArray[columnIndex]?.hardSurface.available;
    const hardSurface_furnitureFilled =
      furnitureArray[columnIndex]?.hardSurface.filled;
    const hardSurface_floatingAvailable =
      floatingArray[columnIndex]?.hardSurface.available;
    const hardSurface_floatingFilled =
      floatingArray[columnIndex]?.hardSurface.filled;
    const hardSurface_glueDownAvailable =
      glueDownArray[columnIndex]?.hardSurface.available;
    const hardSurface_glueDownFilled =
      glueDownArray[columnIndex]?.hardSurface.filled;
    const hardSurface_nailDownAvailable =
      nailDownArray[columnIndex]?.hardSurface.available;
    const hardSurface_nailDownFilled =
      nailDownArray[columnIndex]?.hardSurface.filled;
    const hardSurface_vinylAvailable =
      vinylArray[columnIndex]?.hardSurface.available;
    const hardSurface_vinylFilled = vinylArray[columnIndex]?.hardSurface.filled;
    const hardSurface_tileAvailable =
      tileArray[columnIndex]?.hardSurface.available;
    const hardSurface_tileFilled = tileArray[columnIndex]?.hardSurface.filled;
    const hardSurface_backsplashAvailable =
      backsplashArray[columnIndex]?.hardSurface.available;
    const hardSurface_backsplashFilled =
      backsplashArray[columnIndex]?.hardSurface.filled;

    const softCrewFilteredCount = filteredCandidatesList.filter(
      (crewDocument) => crewDocument?.Scheduling_Surface === "Soft Surface"
    ).length;
    const hardCrewFilteredCount = filteredCandidatesList.filter(
      (crewDocument) => crewDocument?.Scheduling_Surface === "Hard Surface"
    ).length;
    // array(objects) of unassigned but scheduled hard surface jobs for this date
    // 2024-12-18 soft surface now includes Carpet & sheetVinyl (determined by JOB_SHEETVINYL_SKULIST)
    const cellScheduleList = scheduleArray
      .filter(
        (project) =>
          project?.surface !== "Carpet" &&
          crewDocument?.Scheduling_Surface === "Hard Surface"
      )
      .filter(
        (project) =>
          ((new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(project.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(project.end).getTime())) &&
          project?.installer === ""
      )
      .filter(
        (project) =>
          project?.encodedSmallTitle !== "" && project?.encodedLargeTitle !== ""
      )
      .filter((project) => !project?.extraCell) // filter out extraCells;
      .filter((project) => !project?.extraCellId); // filter out jobs married to extraCells;
    // array(objects) of assigned and scheduled soft & hard surface jobs for this date
    const assignedList = scheduleArray
      .filter(
        (project) =>
          ((new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(project.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(project.end).getTime())) &&
          project?.installer !== ""
      )
      .filter(
        (project) =>
          project?.encodedSmallTitle !== "" && project?.encodedLargeTitle !== ""
      );
    // array(strings) of payrollNos for assigned and scheduled soft & hard surface jobs for this date
    const assignedPayrollList = assignedList.map((job) => {
      return job.installer;
    });
    // array(strings) of payrollNos for assigned and scheduled hard surface crews for this date
    const hardAssignedPayrollList = assignedPayrollList.filter(
      (assignedPayrollNo) =>
        filteredCandidatesList.filter(
          (crew) =>
            dataFormatting_payrollFields(crew.payrollNo) ===
              assignedPayrollNo && crew.Scheduling_Surface === "Hard Surface"
        ).length > 0
    );
    // array(strings) of unique payrollNo's
    const hardAssignedUniquePayrollList = [...new Set(hardAssignedPayrollList)];

    if (
      candidateIndex - softCrewFilteredCount < hardCrewFilteredCount - 1 &&
      cellScheduleList[candidateIndex - softCrewFilteredCount]?.id
    ) {
      return (
        // show job by cellIndex
        <JobTile
          key={cellScheduleList[candidateIndex - softCrewFilteredCount]?.id}
          project={cellScheduleList[candidateIndex - softCrewFilteredCount]}
          projectDetailClick={onProjectDetailClick}
          dragStarted={onDragStarted}
        ></JobTile>
      );
    } else if (
      -1 * (candidateIndex - hardCrewFilteredCount - softCrewFilteredCount) <=
      hardAssignedUniquePayrollList.length
    ) {
      return "UNAVAILABLE";
    } else if (
      candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_furnitureAvailable - hardSurface_furnitureFilled ||
      candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_floatingAvailable - hardSurface_floatingFilled ||
      candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_glueDownAvailable - hardSurface_glueDownFilled ||
      candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_nailDownAvailable - hardSurface_nailDownFilled ||
      candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_vinylAvailable - hardSurface_vinylFilled ||
      candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_tileAvailable - hardSurface_tileFilled ||
      candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_backsplashAvailable - hardSurface_backsplashFilled
    ) {
      var resultString = "";
      resultString =
        candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_furnitureAvailable - hardSurface_furnitureFilled
          ? resultString.concat("🪑")
          : resultString;
      resultString =
        candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_floatingAvailable - hardSurface_floatingFilled
          ? resultString.concat("🛟")
          : resultString;
      resultString =
        candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_glueDownAvailable - hardSurface_glueDownFilled
          ? resultString.concat("🖌️")
          : resultString;
      resultString =
        candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_nailDownAvailable - hardSurface_nailDownFilled
          ? resultString.concat("🔨")
          : resultString;
      resultString =
        candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_vinylAvailable - hardSurface_vinylFilled
          ? resultString.concat("📄")
          : resultString;
      resultString =
        candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_tileAvailable - hardSurface_tileFilled
          ? resultString.concat("🪨")
          : resultString;
      resultString =
        candidateIndex + 1 - softCrewFilteredCount <=
        hardSurface_backsplashAvailable - hardSurface_backsplashFilled
          ? resultString.concat("🌊")
          : resultString;
      return resultString;
    } else {
      return "";
    }
    // return candidateIndex - softCrewFilteredCount < hardCrewFilteredCount - 1 &&
    //   cellScheduleList[candidateIndex - softCrewFilteredCount]?.id ? ( // show job by cellIndex
    //   <JobTile
    //     key={cellScheduleList[candidateIndex - softCrewFilteredCount]?.id}
    //     project={cellScheduleList[candidateIndex - softCrewFilteredCount]}
    //     projectDetailClick={onProjectDetailClick}
    //     dragStarted={onDragStarted}
    //   ></JobTile>
    // ) : // this formula reverses/pushes UNAVAILABLE cells to bottom, working up
    // -1 * (candidateIndex - hardCrewFilteredCount - softCrewFilteredCount) <=
    //   hardAssignedUniquePayrollList.length ? (
    //   "UNAVAILABLE"
    // ) : candidateIndex + 1 - softCrewFilteredCount <=
    //   hardSurface_furnitureAvailable - hardSurface_furnitureFilled ? (
    //   "🪑"
    // ) : (
    //   "???"
    // );
  };

  // - - - - - - - - - -

  const renderSoftSurfaceExtraCells = (columnIndex, candidateIndex) => {
    const softExtraCellRowsCount = softExtraCellRowsArray.length;
    // array(objects) of extraCells for soft surface crews for this date
    const softExtraCellList = scheduleArray.filter((project) => {
      const projectDate = new Date(project.start).getTime();
      const columnDate = new Date(weekDatesArray[columnIndex]).getTime();
      return (
        (project?.extraCell &&
          project?.surface === "Carpet" &&
          projectDate === columnDate) ||
        (project?.extraCellId &&
          project?.installer === "" &&
          project?.surface === "Carpet" &&
          projectDate === columnDate)
      );
    });
    //
    //
    // console.log(
    //   "renderSoftSurfaceCells softExtraCellList: ",
    //   softExtraCellList,
    //   ", date: ",
    //   new Date(weekDatesArray[columnIndex]).toLocaleDateString("en-us")
    // );

    return candidateIndex < softExtraCellRowsCount &&
      softExtraCellList[candidateIndex]?.id ? ( // show job by cellIndex
      <JobTile
        key={softExtraCellList[candidateIndex]?.id}
        project={softExtraCellList[candidateIndex]}
        projectDetailClick={onProjectDetailClick}
        dragStarted={onDragStarted}
      ></JobTile>
    ) : // this formula reverses/pushes UNAVAILABLE cells to bottom, working up
    -1 * (candidateIndex - softExtraCellRowsCount) <=
      softExtraCellArray.length ? (
      "UNAVAILABLE"
    ) : (
      ""
    );
  };

  // - - - - - - - - - -

  const renderHardSurfaceExtraCells = (columnIndex, candidateIndex) => {
    const hardExtraCellRowsCount = hardExtraCellRowsArray.length;
    // array(objects) of extraCells for hard surface crews for this date
    const hardExtraCellList = scheduleArray.filter((project) => {
      const projectDate = new Date(project.start).getTime();
      const columnDate = new Date(weekDatesArray[columnIndex]).getTime();
      return (
        (project?.extraCell &&
          project?.surface === "Hard Surface" &&
          projectDate === columnDate) ||
        (project?.extraCellId &&
          project?.installer === "" &&
          project?.surface !== "Carpet" &&
          projectDate === columnDate)
      );
    });
    // console.log(
    //   "renderSoftSurfaceCells hardExtraCellList: ",
    //   hardExtraCellList,
    //   ", date: ",
    //   new Date(weekDatesArray[columnIndex]).toLocaleDateString("en-us")
    // );

    return candidateIndex < hardExtraCellRowsCount &&
      hardExtraCellList[candidateIndex]?.id ? ( // show job by cellIndex
      <JobTile
        key={hardExtraCellList[candidateIndex]?.id}
        project={hardExtraCellList[candidateIndex]}
        projectDetailClick={onProjectDetailClick}
        dragStarted={onDragStarted}
      ></JobTile>
    ) : // this formula reverses/pushes UNAVAILABLE cells to bottom, working up
    -1 * (candidateIndex - hardExtraCellRowsCount) <=
      hardExtraCellArray.length ? (
      "UNAVAILABLE"
    ) : (
      ""
    );
  };

  // - - - - - - - - - -

  useEffect(() => {
    // calculate extraCell values
    var softExtraCellListArray = [];
    var softExtraCellRowsArray = [];
    var softExtraCellCountMax = 0;
    var hardExtraCellListArray = [];
    var hardExtraCellRowsArray = [];
    var hardExtraCellCountMax = 0;

    // loop thru dates for this week
    for (let dateIndex = 0; dateIndex < weekDatesArray.length; dateIndex++) {
      // array(objects) of extraCells for soft surface rows for this date
      const softExtraCellListArrayByDate = scheduleArray.filter((project) => {
        const projectDate = new Date(project.start).getTime();
        const columnDate = new Date(weekDatesArray[dateIndex]).getTime();
        return (
          (project?.extraCell &&
            project?.surface === "Carpet" &&
            projectDate === columnDate) ||
          (project?.extraCellId &&
            project?.installer === "" &&
            project?.surface === "Carpet" &&
            projectDate === columnDate)
        );
      });
      const softExtraCellCount = softExtraCellListArrayByDate.length;

      // array(objects) of extraCells for hard surface rows for this date
      const hardExtraCellListArrayByDate = scheduleArray.filter((project) => {
        const projectDate = new Date(project.start).getTime();
        const columnDate = new Date(weekDatesArray[dateIndex]).getTime();
        return (
          (project?.extraCell &&
            project?.surface === "Hard Surface" &&
            projectDate === columnDate) ||
          (project?.extraCellId &&
            project?.installer === "" &&
            project?.surface !== "Carpet" &&
            projectDate === columnDate)
        );
      });
      const hardExtraCellCount = hardExtraCellListArrayByDate.length;

      // add found extraCell attribute objects to array
      softExtraCellListArray.push(softExtraCellListArrayByDate);
      hardExtraCellListArray.push(hardExtraCellListArrayByDate);

      if (softExtraCellCount > softExtraCellCountMax) {
        // add another row for ExtraCells
        for (
          let extraRowIndex = softExtraCellCountMax;
          extraRowIndex < softExtraCellCount;
          extraRowIndex++
        ) {
          softExtraCellRowsArray.push(extraRowIndex);
        }
        // update new Max
        softExtraCellCountMax = softExtraCellCount;
      }

      if (hardExtraCellCount > hardExtraCellCountMax) {
        // add another row for ExtraCells
        for (
          let extraRowIndex = hardExtraCellCountMax;
          extraRowIndex < hardExtraCellCount;
          extraRowIndex++
        ) {
          hardExtraCellRowsArray.push(extraRowIndex);
        }
        // update new Max
        softExtraCellCountMax = softExtraCellCount;
        hardExtraCellCountMax = hardExtraCellCount;
      }
    }
    setSoftExtraCellRowsArray(softExtraCellRowsArray);
    setSoftExtraCellArray(softExtraCellListArray);
    setHardExtraCellRowsArray(hardExtraCellRowsArray);
    setHardExtraCellArray(hardExtraCellListArray);
    console.log(
      "ScheduleScreen useEffect[scheduleArray] softExtraCellListArray: ",
      softExtraCellListArray,
      ", softExtraCellRowsArray: ",
      softExtraCellRowsArray
    );
    console.log(
      "ScheduleScreen useEffect[scheduleArray] hardExtraCellListArray: ",
      hardExtraCellListArray,
      ", hardExtraCellRowsArray: ",
      hardExtraCellRowsArray
    );
  }, [scheduleArray]);

  // - - - - - - - - - -

  return (
    <div className="schedule-page bg-skin-base">
      <div className="w-60 basis-60 shrink-0">
        <UnassignedProjects />
      </div>
      <div className="flex-auto flex flex-col overflow-hidden ml-6 px-4 pt-8">
        <div>
          <ScheduleHeader></ScheduleHeader>
        </div>
        <div className="overflow-auto h-full w-full mt-8 ">
          <div className="sticky top-0 z-10 text-white">
            <div className="flex min-h-[2.875rem]">
              <div
                className="flex justify-center items-center font-semibold bg-header border-b-2 border-yellow min-w-[8.6rem]
                          p-2 flex-1 overflow-hidden basis-32 first:sticky first:left-0"
              >
                <span>Crews</span>
              </div>
              {renderDateHeaderRow()}
            </div>
          </div>
          {
            // this renders all of the Soft Surface cells, 1 row at a time: crew and job cells
            filteredCandidatesList.map((crew, candidateIndex) => {
              return crew.Scheduling_Surface === "Soft Surface" ? (
                <div
                  className="flex mt-2 [&:nth-child(2)]:mt-0 min-h-12"
                  key={candidateIndex}
                >
                  <ScheduleCell>
                    <div
                      className={`${
                        crew.Scheduling_Surface === "Soft Surface"
                          ? "bg-crew-surface-soft"
                          : "bg-crew-surface-hard"
                      } p-2 h-full`}
                    >
                      {
                        // this renders the crew
                        renderSoftCrewCell(crew, candidateIndex)
                      }
                    </div>
                  </ScheduleCell>
                  {
                    // column_day_array = days names: Mon, Tue, etc
                    COLUMN_DAY_ARRAY.map((value, columnIndex) => (
                      <ScheduleCell key={columnIndex}>
                        {
                          // this renders the job cell
                          renderSoftCellContent(
                            columnIndex,
                            candidateIndex,
                            crew,
                            value
                          )
                        }
                      </ScheduleCell>
                    ))
                  }
                </div>
              ) : (
                ""
              );
            })
          }

          {
            // this renders all of the Soft Surface Extra Cells, 1 row at a time
            softExtraCellRowsArray.map((rowIndex) => {
              return (
                <div
                  className="flex mt-2 [&:nth-child(2)]:mt-0 min-h-12"
                  key={rowIndex}
                >
                  <ScheduleCell>
                    <div className={"bg-crew-surface-soft p-2 h-full"}>
                      <span className="mx-2 self-center font-light w-full text-black text-sm font-semibold flex-1 basis-32 ">
                        {"Extra Soft Cell " + rowIndex}
                      </span>
                    </div>
                  </ScheduleCell>
                  {
                    // column_day_array = days names: Mon, Tue, etc
                    COLUMN_DAY_ARRAY.map((value, columnIndex) => (
                      <ScheduleCell key={columnIndex}>
                        {
                          // this renders the job cell
                          renderSoftExtraCellContent(
                            columnIndex,
                            rowIndex,
                            value
                          )
                        }
                      </ScheduleCell>
                    ))
                  }
                </div>
              );
            })
          }

          {
            // this renders all of the Hard Surface cells, 1 row at a time: crew and job cells
            filteredCandidatesList.map((crew, candidateIndex) => {
              return crew.Scheduling_Surface === "Hard Surface" ? (
                <div
                  className="flex mt-2 [&:nth-child(2)]:mt-0 min-h-12"
                  key={candidateIndex}
                >
                  <ScheduleCell>
                    <div
                      className={`${
                        crew.Scheduling_Surface === "Soft Surface"
                          ? "bg-crew-surface-soft"
                          : "bg-crew-surface-hard"
                      } p-2 h-full`}
                    >
                      {
                        // this renders the crew
                        renderHardCrewCell(crew, candidateIndex)
                      }
                    </div>
                  </ScheduleCell>
                  {
                    // column_day_array = days names: Mon, Tue, etc
                    COLUMN_DAY_ARRAY.map((value, columnIndex) => (
                      <ScheduleCell key={columnIndex}>
                        {
                          // this renders the job cell
                          renderHardCellContent(
                            columnIndex,
                            candidateIndex,
                            crew,
                            value
                          )
                        }
                      </ScheduleCell>
                    ))
                  }
                </div>
              ) : (
                ""
              );
            })
          }

          {
            // this renders all of the Hard Surface Extra Cells, 1 row at a time
            hardExtraCellRowsArray.map((rowIndex) => {
              return (
                <div
                  className="flex mt-2 [&:nth-child(2)]:mt-0 min-h-12"
                  key={rowIndex}
                >
                  <ScheduleCell>
                    <div className={"bg-crew-surface-soft p-2 h-full"}>
                      <span className="mx-2 self-center font-light w-full text-black text-sm font-semibold flex-1 basis-32 ">
                        {"Extra Hard Cell " + rowIndex}
                      </span>
                    </div>
                  </ScheduleCell>
                  {
                    // column_day_array = days names: Mon, Tue, etc
                    COLUMN_DAY_ARRAY.map((value, columnIndex) => (
                      <ScheduleCell key={columnIndex}>
                        {
                          // this renders the job cell
                          renderHardExtraCellContent(
                            columnIndex,
                            rowIndex,
                            value
                          )
                        }
                      </ScheduleCell>
                    ))
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};
