import React from "react";

const getChildrenOnDisplayName = (children, displayName) =>
  React.Children.map(children, (child) =>
    child.type.displayName === displayName ? child : null
  );

const TextArea = ({
                 children,
                 className,
                 rows,
                 placeholder,
                 value,
                 onChange,
                 onEnter,
                 onBlur,
                 disabled,
                 defaultValue,
               }) => {
  const suffix = getChildrenOnDisplayName(children, "suffix");

  // - - - - - - - - - -

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && typeof onEnter === "function") {
      onEnter(event);
    }
  };

  // - - - - - - - - - -

  const blur = (event) => {
    if (typeof onBlur === "function") {
      onBlur(event);
    }
  };

  // - - - - - - - - - -

  return (
    <div
      tabIndex={1}
      className={`flex gap-2.5 h-full px-2 focus-within:outline outline-2 outline-black ${className}`}
    >
      <textarea
        className="h-full w-full bg-inherit outline-none resize-none"
        placeholder={placeholder}
        value={value}
        rows={rows || 1}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={blur}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      {suffix ? <div className="flex gap-2.5 items-center">{suffix}</div> : ""}
    </div>
  );
};

const Suffix = ({ children }) => <>{children}</>;

Suffix.displayName = "suffix";
TextArea.Suffix = Suffix;

export default TextArea;
