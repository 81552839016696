import React, { useEffect, useState, useContext, forwardRef } from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import { ModelContext } from "../../context/model.context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "../../context/app.context";
import TextArea from "../../components/TextArea";

function ExtraCellAddModal({ jobsDetail, setOpenExtraCellAddModal }) {
  const [currPage, setCurrPage] = useState(1);
  const [changesFlag, setChangesFlag] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);
  const [currentExpedite, setCurrentExpedite] = useState(false);
  const [currentDistant, setCurrentDistant] = useState(false);
  const [customText, setCustomText] = useState("");
  const [pagination, setPagination] = useState([]);
  const {
    createJobAttribute,
    setAttributeById,
    setScheduleById,
    createExtraCellAttribute,
  } = useContext(ModelContext);
  const { setOpenSuppressModal, createUuid } = useContext(AppContext);
  const [currentDate, setCurrentDate] = useState();
  const [currentSurface, setCurrentSurface] = useState("Soft Surface");

  // - - - - - - - - - -

  const onCancel = () => {
    if (
      window.confirm("Are you sure you want to exit without saving changes?")
    ) {
      // User clicked OK
      // Close modal window
      setOpenExtraCellAddModal({ show: false });
    } else {
      // User clicked Cancel
    }
  };

  // - - - - - - - - - -

  const onSave = () => {
    //only save if changes have been made
    console.log("ExtraCellAddModal onSave, currentSurface: ", currentSurface);
    if (changesFlag) {
      var surfaceToUse = "";
      if (currentSurface === "Soft Surface" || currentSurface === "Carpet") {
        surfaceToUse = "Carpet";
      } else {
        surfaceToUse = "Hard Surface";
      }
      createExtraCellAttribute(currentDate, surfaceToUse, customText);
      console.log(
        "ExtraCellAddModal onSave, dateL: ",
        currentDate,
        ", surfaceToUse: ",
        surfaceToUse,
        ", customText: ",
        customText
      );
    }

    // Close modal window
    setOpenExtraCellAddModal({ show: false });
  };

  // - - - - - - - - - -

  const onDateChange = (date) => {
    setChangesFlag(true);
    const fmDate = new Date(Date.parse(date)).toLocaleDateString("en-us");
    setCurrentDate(fmDate);
    console.log(
      "ExtraCellAddModal onDateChange, date: ",
      date,
      ", fmDate: ",
      fmDate
    );
  };

  // - - - - - - - - - -

  const onSurfaceChange = (newSurface) => {
    setChangesFlag(true);
    setCurrentSurface(newSurface);
    console.log("ExtraCellAddModal onSurfaceChange, newSurface: ", newSurface);
  };

  // -------

  const onChangeCustomText = (customText) => {
    setChangesFlag(true);
    setCustomText(customText);
  };

  // - - - - - - - - - -

  const EstCompleteInput = forwardRef(({ value, onClick, className }, ref) => (
    <button
      onClick={onClick}
      ref={ref}
      className="flex items-center hover:opacity-75 cursor-pointer"
    >
      <span className={className}>{value}</span>
      <span className="ml-1 arrow arrow-down arrow-small"></span>
    </button>
  ));

  // - - - - - - - - - -

  return (
    <Modal
      onClose={() =>
        !changesFlag ? setOpenExtraCellAddModal({ show: false }) : onCancel()
      }
    >
      <Modal.Header>
        <div className="flex justify-between">
          <div>Extra Cell</div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="flex flex-col gap-4 w-[400px]">
          <div className="text-lg font-bold">Required information</div>
          <div className="flex flex-wrap">
            <div className="flex-1 basis-2/4">Date</div>
            <div className="flex-1 basis-2/4 font-bold">
              <DatePicker
                selected={currentDate}
                onChange={(date) => onDateChange(date)}
                customInput={
                  <EstCompleteInput className="flex-1 basis-2/4 font-bold text-skin-link" />
                }
                dateFormat="M/d/YYYY"
              />
            </div>
            <div className="flex-1 basis-2/4">Surface</div>
            <div className="flex-1 basis-2/4 text-black">
              <select
                className="bg-yellow-30 text-black text-sm font-medium hover:opacity-75 cursor-pointer"
                value={currentSurface}
                onChange={(e) => onSurfaceChange(e.target.value)}
              >
                <option value="Soft Surface">Soft Surface</option>
                <option value="Hard Surface">Hard Surface</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-1 p-2 bg-skin-surface-hard">
            <div>
              <TextArea
                placeholder="Custom Notes"
                rows={10}
                value={customText}
                onChange={(e) => onChangeCustomText(e.target.value)}
                className="bg-skin-base border border-black border-opacity-20 "
              ></TextArea>
            </div>
          </div>
          <div></div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-col items-center w-full gap-4">
          <div className="h-8 flex justify-between w-full">
            <Button
              className="uppercase w-31"
              onClick={() => onSave()}
              disabled={!changesFlag}
            >
              Save
            </Button>
            {changesFlag ? (
              <Button
                color="light"
                className="uppercase w-31"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ExtraCellAddModal;
