import React, { useEffect, useState, useContext, forwardRef } from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { ModelContext } from "../../context/model.context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "../../context/app.context";
import TextArea from "../../components/TextArea";

function ExtraCellDetailModal({ jobsDetail, setOpenExtraCellDetailModal }) {
  const [currPage, setCurrPage] = useState(1);
  const [changesFlag, setChangesFlag] = useState(false);
  const [currentJobAttribute, setcurrentJobAttribute] = useState(null);
  const [customText, setCustomText] = useState("");
  const [pagination, setPagination] = useState([]);
  const { createExtraCellAttribute, removeExtraCellAttribute } =
    useContext(ModelContext);
  const { setOpenSuppressModal } = useContext(AppContext);
  const [currentId, setCurrentId] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [currentSurface, setCurrentSurface] = useState("Soft Surface");

  // - - - - - - - - - -

  const onDelete = () => {
    if (window.confirm("Are you sure you want to remove this extra cell?")) {
      // User clicked OK
      // Close modal window
      setOpenExtraCellDetailModal({ show: false });

      removeExtraCellAttribute(currentJobAttribute.id);
      //
    } else {
      // User clicked Cancel
    }
  };

  // - - - - - - - - - -

  useEffect(() => {
    const incomingAttribute = jobsDetail[0];
    var surfaceToUse = "";
    if (incomingAttribute?.surface === "Carpet") {
      surfaceToUse = "Soft Surface";
    } else {
      surfaceToUse = "Hard Surface";
    }

    setcurrentJobAttribute(incomingAttribute);
    setCurrentId(incomingAttribute?.id);
    setCurrentDate(incomingAttribute?.start);
    setCurrentSurface(surfaceToUse);
    setCustomText(incomingAttribute?.encodedSmallTitle);

    console.log(
      "ExtraCellDetailModal useEffect[currPage, jobsDetail] incomingAttribute: ",
      incomingAttribute
    );
  }, [currPage, jobsDetail]);

  // - - - - - - - - - -

  return (
    <Modal onClose={() => setOpenExtraCellDetailModal({ show: false })}>
      <Modal.Header>
        <div className="flex justify-between">
          <div>Extra Cell</div>
        </div>
      </Modal.Header>
      <Modal.Content>
        {currentJobAttribute ? (
          <div className="flex flex-col gap-4 w-[400px]">
            <div className="flex flex-wrap">
              <div className="flex-1 basis-2/4">Date</div>
              <div className="flex-1 basis-2/4 font-bold">{currentDate}</div>
              <div className="flex-1 basis-2/4">Surface</div>
              <div className="flex-1 basis-2/4">{currentSurface}</div>
            </div>
            <div className="flex flex-col gap-1 p-2 bg-skin-surface-hard">
              <div>
                <TextArea
                  placeholder="Custom Notes"
                  rows={10}
                  value={customText}
                  readOnly={true}
                  disabled={true}
                  className="bg-skin-base border border-black border-opacity-20 "
                ></TextArea>
              </div>
            </div>
            <div></div>
          </div>
        ) : (
          "Extra cell not found"
        )}
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-col items-center w-full gap-4">
          <div className="h-8 flex justify-between w-full">
            <Button className="uppercase w-31" onClick={() => onDelete()}>
              Delete
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ExtraCellDetailModal;
